<template>
    <div class="flipbook-container">
        <flipbook 
            :ambient="0.8"
            :gloss="0.1"
            class="flipbook"
            :pages="pages"
            :pagesHiRes="pagesHiRes"
            :startPage="pageNum"
            v-slot="flipbook"
            ref="flipbook">
        <div class="action-bar">
            <a href="#" @click.prevent="flipbook.flipLeft" aria-label="Navigate Flipbook Control Flip Left">
              <img src="/images/flipbook-left.svg" class="control-button" :class="{ disabled: !flipbook.canFlipLeft }" alt="Left Control" >
            </a>
            <a href="#" @click.prevent="flipbook.zoomIn" aria-label="Navigate Flipbook Control Zoom In">
              <img src="/images/flipbook-plus.svg" class="control-button" :class="{ disabled: !flipbook.canZoomIn }" alt="Zoom In">
            </a>
            <span class="page-num">
                Page {{ flipbook.page }} of {{ flipbook.numPages }}
            </span>
            <a href="#" @click.prevent="flipbook.zoomOut" aria-label="Navigate Flipbook Control Zoom Out">
              <img src="/images/flipbook-minus.svg" class="control-button" :class="{ disabled: !flipbook.canZoomOut }" alt="Zoom Out">
            </a>
            <a href="#" @click.prevent="flipbook.flipRight" aria-label="Navigate Flipbook Control Flip Right">
              <img src="/images/flipbook-right.svg" class="control-button" :class="{ disabled: !flipbook.canFlipRight }" alt="Right Control">
            </a>
        </div>
        </flipbook>
    </div>
</template>

<script>
import Flipbook from 'flipbook-vue'

export default {
    name: "FlipbookView",
    components: { Flipbook },
    data(){
        return{
            pages:[
                null,
                '/images/flipbook/cerda-zein-flipbook-pg-001-COVER.jpg',
                '/images/flipbook/cerda-zein-flipbook-pg-002.jpg',
                '/images/flipbook/cerda-zein-flipbook-pg-003.jpg',
                '/images/flipbook/cerda-zein-flipbook-pg-004.jpg',
                '/images/flipbook/cerda-zein-flipbook-pg-005.jpg',
                '/images/flipbook/cerda-zein-flipbook-pg-006.jpg',
                '/images/flipbook/cerda-zein-flipbook-pg-007.jpg',
                '/images/flipbook/cerda-zein-flipbook-pg-008-REVERSE.jpg',
            ],
            pagesHiRes: [],
            hasMouse: true,
            pageNum: null,
        }
    },
    metaInfo: {
        title: 'Flipbook',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
}
</script>

<style lang="scss">
.flipbook-container{
  min-height: 100vh;
}
.flipbook {
   width: 90vw;
   height: calc(100vh - 80px);
   margin: 0 auto;
   margin-top: 1rem;
}
.bounding-box{
    box-shadow: 0 0 2px #000;
}
.action-bar {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-bar .control-button {
  height: 30px;
  width: 30px;
}
.action-bar .control-button:not(:first-child) {
  margin-left: 16px;
}
.action-bar .control-button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.action-bar .icon-button:active {
  filter: none !important;
}
.action-bar .control-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 16px;
  margin-right: 16px
}
.flipbook .viewport {
  width: 90vw;
  height: calc(100vh - 48px + 30px + 16px);
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}
</style>
